import React, { useEffect, useState } from 'react';
import { Table, Empty, ConfigProvider, Spin } from 'antd';
import { ResourceProprieties } from '../../assets/resource';
import {
    columnRegion,
    columnResourceType,
    columns as defaultColumns,
    ColumnsProprieties,
    FilterContainer,
    getTagSorter,
    RemoveDuplicateFromArray,
} from 'assets/table_columns';
import { TagSuggestions } from '../../assets/tag/tagSuggestions';
import {
    call,
    formatResources,
    updateMostUsedTagsFilters,
} from '../../shared/utils';
import { disconnect } from '../../shared/utils/storage';
import { useHistory, useLocation } from 'react-router-dom';
import { RenderTag } from '../../assets/tag/render';

const TagTable = () => {
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const [columnsData, setColumnsData] = useState(defaultColumns);
    const [tableData, setTableData] = useState<
        ResourceProprieties[] | undefined
    >(undefined);

    const formatTable = async (
        filledColumnsData: ColumnsProprieties[],
        mostUsedTags: string[],
    ) => {
        try {
            const suggestions: TagSuggestions = {};
            for (let i = 0, len = mostUsedTags.length; i < len; i++) {
                const res = await call(
                    '/tagging/suggestions/tag-value?tagkey=' + mostUsedTags[i],
                    'GET',
                );
                suggestions[res.data.tagKey] = res.data.suggestions;
            }
            const filters: FilterContainer = {
                region: [],
                type: [],
            };
            const colCpy = [...filledColumnsData];
            const resourcesRes = await call('/tagging/resources', 'POST', {
                accounts: [],
                regions: [],
                resourceTypes: [],
                tags: [],
                missingTags: [],
            });
            setTableData([
                ...formatResources(resourcesRes.data, filters, suggestions),
            ]);
            RemoveDuplicateFromArray(
                filters.type,
                colCpy[columnResourceType].filters,
            );
            RemoveDuplicateFromArray(
                filters.region,
                colCpy[columnRegion].filters,
            );
            updateMostUsedTagsFilters(resourcesRes.data, filters, colCpy);
            setColumnsData([...colCpy]);
        } catch (error) {
            if (error.response != null)
                if (error.response.status === 401)
                    disconnect(history, location);
            setTableData([]);
        }
    };

    const fetchMostUsedTags = async () => {
        const filledColumnsData = [...defaultColumns];
        try {
            const mostUsedTagsRes = await call('/tagging/mostusedtags', 'GET');

            const filteredMostUsedTags = mostUsedTagsRes.data.mostUsedTags.filter(
                (tag: string) => tag !== 'Name',
            );

            filteredMostUsedTags.forEach((mostUsedTag: string) => {
                filledColumnsData.push({
                    title: mostUsedTag,
                    key: mostUsedTag,
                    dataIndex: mostUsedTag,
                    sorter: getTagSorter(mostUsedTag),
                    sortDirections: ['ascend', 'descend'],
                    filters: [],
                    render: RenderTag,
                    onFilter: (
                        value: string | number | boolean,
                        record: ResourceProprieties,
                    ): boolean => {
                        try {
                            return record[mostUsedTag].text === value;
                        } catch (err) {
                            return false;
                        }
                    },
                });
            });
            await formatTable(
                filledColumnsData,
                mostUsedTagsRes.data.mostUsedTags,
            );
        } catch (error) {
            if (error.response != null) {
                if (error.response.status === 401)
                    disconnect(history, location);
            }
            setTableData([]);
        }
    };

    useEffect(() => {
        fetchMostUsedTags();
    }, []);

    return (
        <div
            style={{
                width: '100%',
                backgroundColor: '#F4F4F4',
                borderRadius: '10px',
                padding: '15px',
                marginBottom: '75px',
            }}
        >
            <h3>Resource list</h3>
            <ConfigProvider
                renderEmpty={() => {
                    return (
                        <span>
                            {tableData?.length === 0 ? (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No data"
                                />
                            ) : null}
                        </span>
                    );
                }}
            >
                <Table
                    style={{
                        border: '1px solid #CFCFCF',
                        borderBottomLeftRadius: '5px',
                        borderBottomRightRadius: '5px',
                        backgroundColor: '#F4F4F4',
                    }}
                    loading={{
                        spinning: tableData === undefined,
                        indicator: <Spin style={{ marginTop: '1em' }} />,
                    }}
                    scroll={{ x: true }}
                    bordered={false}
                    columns={columnsData}
                    dataSource={tableData}
                />
            </ConfigProvider>
        </div>
    );
};

export default TagTable;
