import { trackEvents } from './analytics';
import * as H from 'history';

export const saveToken = (token: string) => {
    window.localStorage.setItem('accessToken', token);
};

export const deleteToken = () => {
    window.localStorage.removeItem('accessToken');
};

export const getToken = () => {
    return window.localStorage.getItem('accessToken');
};

export const disconnect = (
    history: H.History<H.History.UnknownFacade>,
    location: H.Location<{ from: string }>,
) => {
    const email = localStorage.getItem('email');
    if (email) {
        trackEvents({
            event: 'Logout',
            properties: {
                value: email,
                category: 'Account',
                label: 'User disconnected',
            },
        });
    }
    deleteToken();
    const { from } = location.state || { from: { path: '/login' } };
    history.replace(from);
    localStorage.removeItem('reminderPopup');
};
