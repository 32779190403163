import React, { useContext } from 'react';
import { CustomMenu } from '../../shared/components/Menu';
import { Button, Dropdown, Menu } from 'antd';
import {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import SubscriptionModal from './Modals/SubscriptionModal';
import { ModalContext } from './Modals/ModalContext';
import { disconnect } from '../../shared/utils/storage';
import { useHistory, useLocation } from 'react-router-dom';

const TagbotHeader = () => {
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const {
        setAwsAccountModal,
        setSubscriptionInfoModal,
        setExportDataModal,
    } = useContext(ModalContext);

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() => setAwsAccountModal(true)}
                icon={<UserOutlined />}
            >
                AWS accounts
            </Menu.Item>
            <Menu.Item
                icon={<SettingOutlined />}
                onClick={() => {
                    setSubscriptionInfoModal(true);
                }}
            >
                Account settings
            </Menu.Item>
            <Menu.Item
                icon={<ExportOutlined />}
                onClick={() => {
                    setExportDataModal(true);
                }}
            >
                Export data
            </Menu.Item>
            <Menu.Item
                onClick={() => disconnect(history, location)}
                icon={<LogoutOutlined />}
            >
                Disconnect
            </Menu.Item>
        </Menu>
    );

    return (
        <CustomMenu
            theme="light"
            mode="horizontal"
            style={{
                height: '8vh',
                minHeight: '47px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CustomMenu.Item
                style={{
                    height: '100%',
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img
                    style={{
                        height: '4vh',
                        minHeight: '26px',
                        width: 'auto',
                    }}
                    src="/logo.png"
                    alt="logo"
                />
            </CustomMenu.Item>
            <CustomMenu.Item
                style={{
                    float: 'right',
                    marginRight: '1rem',
                    height: '100%',
                    width: '50%',
                    cursor: 'inherit',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Dropdown overlay={menu}>
                        <Button
                            type="primary"
                            style={{
                                height: '4vh',
                                minHeight: '30px',
                                fontSize: '90%',
                            }}
                        >
                            <UserOutlined /> {localStorage.getItem('email')}
                        </Button>
                    </Dropdown>
                </div>
            </CustomMenu.Item>
            <SubscriptionModal />
        </CustomMenu>
    );
};

export default TagbotHeader;
