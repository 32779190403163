import { ResourceProprieties, ResourceTag } from 'assets/resource';
import {
    FilterContainer,
    RemoveDuplicateForMostUsedTags,
} from 'assets/table_columns';
import { TagSuggestions } from 'assets/tag/tagSuggestions';
import { ColumnsProprieties } from 'assets/table_columns';

export const formatResources = (
    resources: ResourceProprieties[],
    refFilterContainer: FilterContainer,
    suggestions: TagSuggestions,
) => {
    return resources.map((resource: ResourceProprieties, index: number) => {
        refFilterContainer.type.push(resource.resourceType);
        refFilterContainer.region.push(resource.region);
        resource.key = index;
        resource.tags.forEach((tagPair: ResourceTag) => {
            resource[tagPair.key] = {
                suggestion: false,
                text: tagPair.value,
            };
        });
        Object.keys(suggestions).forEach((key: string) => {
            if (!(key in resource)) {
                resource[key] = {
                    suggestion: true,
                    text: Object.entries(suggestions[key])
                        .reduce((acc, [, val]) => acc + val.value + '|', '')
                        .slice(0, -1),
                    dataSuggestion: suggestions[key],
                    resourceUrl: resource.url,
                    cloudFormationUrl: resource.cloudFormationUrl,
                };
            }
        });
        return resource;
    });
};

export const isMostUsedTags = (tag: string) =>
    !['resourceId', 'resourceType', 'region', 'name'].includes(tag);

export const updateMostUsedTagsFilters = (
    resources: ResourceProprieties[],
    refFilters: FilterContainer,
    columns: ColumnsProprieties[],
) => {
    resources.forEach((resource: ResourceProprieties) => {
        columns.forEach((column) => {
            const res_key: string = column.key;
            if (isMostUsedTags(res_key)) {
                if (
                    typeof resource[res_key] != 'string' &&
                    resource[res_key] != undefined &&
                    resource[res_key].suggestion != undefined &&
                    resource[res_key].suggestion === false
                ) {
                    if (!refFilters[res_key]) refFilters[res_key] = [];
                    refFilters[res_key].push(resource[res_key].text);
                } else if (
                    typeof resource[res_key] === 'string' &&
                    resource[res_key] != undefined
                ) {
                    if (!refFilters[res_key]) refFilters[res_key] = [];
                    refFilters[res_key].push(resource[res_key]);
                }
            }
        });
    });
    RemoveDuplicateForMostUsedTags(refFilters, columns);
};
