import axios, { Method } from 'axios';
import { config, getToken } from 'shared/utils';

interface HeaderType {
    [headers: string]: string;
}

export const call = (route: string, method: Method, body = {}) => {
    const headers: HeaderType = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    const token = getToken();
    if (token !== null) headers['Authorization'] = token;

    return axios({
        method,
        url: config.API_URL + route,
        data: body,
        headers,
    });
};

interface QueryArgType {
    [args: string]: number | string | boolean;
}

export const callWithQueryParams = (
    route: string,
    method: Method,
    queryArg: QueryArgType,
    body = {},
) => {
    const url = Object.entries(queryArg)
        .reduce((acc, [key, val]) => acc + `${key}=${val}&`, `${route}?`)
        .slice(0, -1);
    return call(url, method, body);
};

export interface TagData {
    key: string;
    value: string;
}

export interface ResourceData {
    account: string;
    reportDate: string;
    resourceId: string;
    resourceType: string;
    region: string;
    url: string;
    tags: TagData[];
}

export const getMostUsedTags = async (): Promise<string[] | null> => {
    try {
        const mostUsedTagsRes = await call('/tagging/mostusedtags', 'GET');
        return mostUsedTagsRes.data.mostUsedTags as string[];
    } catch (err) {
        return null;
    }
};

export const getUserAwsResources = async (): Promise<ResourceData[] | null> => {
    try {
        const resourcesRes = await call('/tagging/resources', 'POST', {
            accounts: [],
            regions: [],
            resourceTypes: [],
            tags: [],
            missingTags: [],
        });
        return resourcesRes.data.map((key: ResourceData) => {
            return key;
        });
    } catch (err) {
        return null;
    }
};
