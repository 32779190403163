interface Point {
    x: number;
    y: number;
}

interface Dataset {
    label: string;
    fill: boolean;
    lineTension: number;
    backgroundColor: string;
    borderColor: string;
    borderCapStyle: string;
    borderDash: [];
    borderDashOffset: number;
    borderJoinStyle: string;
    pointBorderColor: string;
    pointBackgroundColor: string;
    pointBorderWidth: number;
    pointHoverRadius: number;
    pointHoverBackgroundColor: string;
    pointHoverBorderColor: string;
    pointHoverBorderWidth: number;
    pointRadius: number;
    pointHitRadius: number;
    data: Point[];
}

interface Chart {
    labels: string[];
    datasets: Dataset[];
}

export const DEFAULT_DAY_RANGE = 7;

export const chartData: Chart = {
    labels: [''],
    datasets: [
        {
            label: 'Totally untagged',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(207, 19, 34, .9)',
            borderColor: 'rgba(207, 19, 34, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(207, 19, 34, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(207, 19, 34, 1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [],
        },
        {
            label: 'Partially tagged',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(250, 173, 20, .9)',
            borderColor: 'rgba(250, 173, 20, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(250, 173, 20, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(250, 173, 20, 1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [],
        },
        {
            label: 'Fully tagged',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(95, 196, 45, .9)',
            borderColor: 'rgba(95, 196, 45, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(95, 196, 45, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(95, 196, 45, 1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [],
        },
    ],
};
