import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Form, Layout, Input, Button, Alert } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { layout, tailLayout } from './layouts';
import { call, trackEvents } from '../../shared/utils';
import { LogUser } from './Login';

const Register = () => {
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const [error, setError] = useState('');

    const onFinish = async (values: Store) => {
        const { email, password, discountCode } = values;
        try {
            await call('/user', 'POST', {
                email,
                password,
                discountCode,
                origin: 'tagbot',
            });
            trackEvents({
                event: 'Register',
                properties: {
                    value: email,
                    category: 'Account',
                    label: 'New user registered',
                },
            });
            await LogUser(email, password, history, location);
        } catch (error) {
            if (
                error.response != null &&
                error.response.data != null &&
                error.response.data['error']
            )
                setError(error.response.data['error']);
            else setError('An error occurred, please try again later.');
        }
    };

    const onCancelRegistration = () => {
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
    };

    return (
        <Layout
            style={{
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
            }}
        >
            <img className={'auth-logo'} src="logo.png" alt="logo" />
            <Form
                style={{ width: '100%' }}
                {...layout}
                name="basic"
                layout={'vertical'}
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Email address"
                    name="email"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email address!',
                            type: 'email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Confirm your password"
                    name="confirm_password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    'The two passwords that you entered do not match!',
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Discount code"
                    name="discountCode"
                    hasFeedback
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%', marginBottom: '10px' }}
                    >
                        Sign up
                    </Button>
                    <Button
                        type="default"
                        style={{ width: '100%', marginBottom: '10px' }}
                        href="https://aws.amazon.com/marketplace/pp/B08F7YHRQ5"
                    >
                        Sign Up using AWS Marketplace
                    </Button>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="default"
                        htmlType="submit"
                        style={{ width: '100%' }}
                        onClick={onCancelRegistration}
                    >
                        Already have an account
                    </Button>
                </Form.Item>

                {error && (
                    <Form.Item {...tailLayout}>
                        <Alert message={error} type="error" showIcon />
                    </Form.Item>
                )}
            </Form>
        </Layout>
    );
};

export default Register;
