/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import TagChart from './TagChart';
import TagDoughnut from './TagDoughnut';
import FilterComponent from 'shared/components/FilterComponent';
import { Layout } from 'antd';
import { call, dateToBackendString } from '../../shared/utils';
import {
    DEFAULT_DAY_RANGE,
    chartData as defaultChartData,
} from '../../assets/charts_data';
import { disconnect } from '../../shared/utils/storage';
import { useHistory, useLocation } from 'react-router-dom';

const ChartsContainer = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Chart = require('react-chartjs-2').Chart;
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const [latestComplianceData, setLatestComplianceData] = useState({
        totallyTagged: 0,
        partiallyTagged: 0,
        notTagged: 0,
        total: 1,
    });
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [chartData, setChartData] = useState<any>({});

    const fetchCompliance = async () => {
        const date = new Date();
        const firstDay = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - DEFAULT_DAY_RANGE,
        );
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        try {
            const complianceRes = await call(
                `/tagging/compliance?begin=${dateToBackendString(
                    firstDay,
                )}&end=${dateToBackendString(lastDay)}`,
                'GET',
            );

            const keys = Object.keys(complianceRes.data);
            const filledChartData = { ...defaultChartData };

            if (keys.length !== 0) {
                setLatestComplianceData(
                    complianceRes.data[keys[keys.length - 1]],
                );
                filledChartData.labels = [];
                keys.forEach((key: string) => {
                    const data = [
                        complianceRes.data[key].notTagged,
                        complianceRes.data[key].partiallyTagged,
                        complianceRes.data[key].totallyTagged,
                    ];
                    const timeDiff = Date.now() - new Date(key).getTime();
                    const dayDiff = timeDiff / (1000 * 3600 * 24);
                    const fixedDayDiff = parseInt(dayDiff.toFixed(0));
                    data.forEach((data, index) => {
                        filledChartData.datasets[index].data.push({
                            x: fixedDayDiff,
                            y: Math.round(
                                (data / complianceRes.data[key].total) * 100.0,
                            ),
                        });
                    });
                });
            }
            setChartData(filledChartData);
        } catch (error) {
            if (error.response != null && error.response.status === 401)
                disconnect(history, location);
        }
    };

    const configureChartsGlobally = () => {
        Chart.pluginService.register({
            beforeDraw: function (chart: any) {
                if (chart.config.options.elements.center) {
                    //Get ctx from string
                    const ctx: any = chart.chart.ctx;

                    //Get options from the center object in options
                    const centerConfig: any =
                        chart.config.options.elements.center;
                    const fontStyle: any = centerConfig.fontStyle || 'Arial';
                    const txt: any = centerConfig.text;
                    const color: any = centerConfig.color || '#000';
                    const sidePadding: any = centerConfig.sidePadding || 20;
                    const sidePaddingCalculated: any =
                        (sidePadding / 100) * (chart.innerRadius * 2);
                    //Start with a base font of 30px
                    ctx.font = '45px ' + fontStyle;

                    //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    const stringWidth: any = ctx.measureText(txt).width;
                    const elementWidth: any =
                        chart.innerRadius * 2 - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    const widthRatio: any = elementWidth / stringWidth;
                    const newFontSize: any = Math.floor(30 * widthRatio);
                    const elementHeight: any = chart.innerRadius * 2;

                    // Pick a new font size so it will not be larger than the height of label.
                    const fontSizeToUse: any = Math.min(
                        newFontSize,
                        elementHeight,
                    );

                    //Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    const centerX: any =
                        (chart.chartArea.left + chart.chartArea.right) / 2;
                    const centerY: any =
                        (chart.chartArea.top + chart.chartArea.bottom) / 2;
                    ctx.font = fontSizeToUse + 'px ' + fontStyle;
                    ctx.fillStyle = color;

                    //Draw text in center
                    ctx.fillText(txt, centerX, centerY);
                }
                if (chart.config.options.showAllTooltips) {
                    // create an array of tooltips
                    // we can't use the chart tooltip because there is only one tooltip per chart
                    chart.pluginTooltips = [];
                    chart.config.data.datasets.forEach(function (
                        dataset: any,
                        i: any,
                    ) {
                        chart
                            .getDatasetMeta(i)
                            .data.forEach(function (sector: any) {
                                chart.pluginTooltips.push(
                                    new Chart.Tooltip(
                                        {
                                            _chart: chart.chart,
                                            _chartInstance: chart,
                                            _data: chart.data,
                                            _options: chart.options.tooltips,
                                            _active: [sector],
                                        },
                                        chart,
                                    ),
                                );
                            });
                    });

                    // turn off normal tooltips
                    chart.options.tooltips.enabled = false;
                }
            },
            afterDraw: function (chart: any, easing: any) {
                if (chart.config.options.showAllTooltips) {
                    // we don't want the permanent tooltips to animate, so don't do anything till the animation runs at least once
                    if (!chart.allTooltipsOnce) {
                        if (easing !== 1) return;
                        chart.allTooltipsOnce = true;
                    }

                    // turn on tooltips
                    chart.options.tooltips.enabled = true;
                    Chart.helpers.each(chart.pluginTooltips, function (
                        tooltip: any,
                    ) {
                        tooltip.initialize();
                        tooltip.update();
                        // we don't actually need this since we are not animating tooltips
                        tooltip.pivot();
                        tooltip.transition(easing).draw();
                    });
                    chart.options.tooltips.enabled = false;
                }
            },
        });
    };

    useEffect(() => {
        configureChartsGlobally();
        fetchCompliance();
    }, []);

    return (
        <Layout
            style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: '1rem',
                backgroundColor: '#FFF',
                minWidth: '350px',
            }}
        >
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#F4F4F4',
                    borderRadius: '10px',
                    padding: '15px',
                }}
            >
                <h3>Tag summary</h3>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '65vw',
                            minWidth: '237px',
                            height: '350px',
                        }}
                    >
                        <FilterComponent
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                        />
                        <TagChart
                            chartData={chartData}
                            selectedCategory={selectedCategory}
                        />
                    </div>
                    <div
                        style={{
                            width: '30vw',
                            minWidth: '370px',
                        }}
                    >
                        <TagDoughnut
                            latestComplianceData={latestComplianceData}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ChartsContainer;
