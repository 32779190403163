export const barChartData = (latestComplianceData: {
    [key: string]: number;
}) => ({
    datasets: [
        {
            data: [
                (latestComplianceData.notTagged / latestComplianceData.total) *
                    100,
                (latestComplianceData.partiallyTagged /
                    latestComplianceData.total) *
                    100,
                (latestComplianceData.totallyTagged /
                    latestComplianceData.total) *
                    100,
            ],
            backgroundColor: ['#CF1322', '#FAAD14', '#5FC42D'],
        },
    ],
    labels: [
        ` ${latestComplianceData.notTagged} of ${latestComplianceData.total} resources totally untagged\n`,
        ` ${latestComplianceData.partiallyTagged} of ${latestComplianceData.total} resources partially tagged`,
        ` ${latestComplianceData.totallyTagged} of ${latestComplianceData.total} resources fully tagged`,
    ],
});
