import React from 'react';

export type ModalContextValues = {
    awsAccountModal: boolean;
    setAwsAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
    hasAwsAccLinked: React.MutableRefObject<boolean>;
    subscriptionModal: boolean;
    setSubscriptionModal: React.Dispatch<React.SetStateAction<boolean>>;
    subscriptionInfoModal: boolean;
    setSubscriptionInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
    reminderVisibility: boolean;
    setReminderVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    exportData: boolean;
    setExportDataModal: React.Dispatch<React.SetStateAction<boolean>>;
};

/* eslint-disable @typescript-eslint/no-empty-function */
export const ModalContext = React.createContext<ModalContextValues>({
    awsAccountModal: false,
    setAwsAccountModal: () => {},
    hasAwsAccLinked: {
        current: false,
    },
    subscriptionModal: false,
    setSubscriptionModal: () => {},
    subscriptionInfoModal: false,
    setSubscriptionInfoModal: () => {},
    reminderVisibility: false,
    setReminderVisibility: () => {},
    exportData: false,
    setExportDataModal: () => {},
});
