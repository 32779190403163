import { RouteProps } from 'react-router-dom';
import { Dashboard, Login, Register } from 'pages';

export interface Routes extends RouteProps {
    public: boolean;
}

export const routes: Array<Routes> = [
    {
        public: true,
        path: '/login',
        exact: true,
        component: Login,
    },
    {
        public: true,
        path: '/register',
        exact: true,
        component: Register,
    },
    {
        public: false,
        path: '',
        exact: true,
        component: Dashboard,
    },
];
