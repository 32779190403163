import React, { useContext } from 'react';
import { Modal } from 'antd';
import { CreateStripeCustomer } from '../../stripe/CreateStripeCustomer';
import { ModalContext } from './ModalContext';

const SubscriptionModal = () => {
    const { subscriptionModal } = useContext(ModalContext);

    return (
        <Modal
            title="Free Trial expired"
            footer={null}
            closable={false}
            visible={subscriptionModal}
        >
            <CreateStripeCustomer />
        </Modal>
    );
};

export default SubscriptionModal;
