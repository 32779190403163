import React, { Component, ReactText } from 'react';
import { Button, Input, Modal, Tree } from 'antd';
import { call } from '../../shared/utils';
import { callWithQueryParams } from 'shared/utils';
import {
    AWSAcc,
    DisplayPopupError,
    FullAWSAccountData,
    GenericModalProprieties,
    IsValidArn,
    MinimalAWSAccountData,
    TreeDataType,
} from './utils';
import { AddAwsAccount } from './addAWSAcc';
import { DeleteTwoTone, DownOutlined, EditTwoTone } from '@ant-design/icons';

export class HandleAWSAccounts extends Component<
    GenericModalProprieties<boolean>,
    AWSAcc<boolean>
> {
    constructor(props: GenericModalProprieties<boolean>) {
        super(props);
        const emptyAccList: FullAWSAccountData[] = [];
        this.state = {
            accountList: emptyAccList,
            setAttribute: props.setAttribute,
            modalVisibility: {
                accSelected: false,
                accCreation: false,
            },
            accountSelected: {
                data: {
                    accountOwner: false,
                    awsIdentity: '',
                    billRepositories: [],
                    id: 0,
                    payer: false,
                    permissionLevel: 0,
                    pretty: '',
                    roleArn: '',
                    subAccounts: [],
                },
                index: 0,
                parentIndex: -1,
            },
            treeData: [
                {
                    key: '',
                    title: '',
                    children: [],
                    disabled: false,
                    selectable: true,
                    icon: null,
                },
            ],
            loadingDeleteButton: false,
        };
        this.getAccList = this.getAccList.bind(this);
        this.closeMainModal = this.closeMainModal.bind(this);
        this.getListFullAwsAccount = this.getListFullAwsAccount.bind(this);
        this.formatDataTreeFormAccounts = this.formatDataTreeFormAccounts.bind(
            this,
        );
        this.setSelectedAccount = this.setSelectedAccount.bind(this);
        this.confirmInput = this.confirmInput.bind(this);
    }

    componentDidMount = async () => {
        await this.getAccList();
    };

    getAccList = async () => {
        try {
            const result = await call('/aws', 'GET');
            this.getListFullAwsAccount(result.data[0]).then(
                (accList: FullAWSAccountData[]) => {
                    this.setState({
                        ...this.state,
                        accountList: accList,
                    });
                    this.formatDataTreeFormAccounts();
                },
            );
        } catch (error) {
            if (
                error.response != null &&
                error.response.data != null &&
                error.response.data['error']
            )
                DisplayPopupError(
                    error.response.data['error'],
                    this.closeMainModal,
                );
        }
    };

    closeMainModal = () => {
        this.state.setAttribute(false);
        this.setState({
            ...this.state,
            modalVisibility: {
                ...this.state.modalVisibility,
                accSelected: false,
            },
        });
    };

    getListFullAwsAccount = async (randomAccount: MinimalAWSAccountData) => {
        const accountArray: Array<FullAWSAccountData> = [];
        await (async (value: MinimalAWSAccountData) => {
            const result = await callWithQueryParams('/aws/status', 'GET', {
                'account-id': value.id,
            });
            result.data.forEach((account: FullAWSAccountData) => {
                accountArray.push(account);
            });
        })(randomAccount);
        return accountArray;
    };

    setSelectedAccount = (index: number, parentIndex?: number) => {
        this.setState({
            ...this.state,
            accountSelected: {
                data:
                    parentIndex != undefined
                        ? this.state.accountList[parentIndex].subAccounts[index]
                        : this.state.accountList[index],
                index: index,
                parentIndex: parentIndex || -1,
            },
            modalVisibility: {
                ...this.state.modalVisibility,
                accSelected: true,
            },
        });
    };

    formatDataTreeFormAccounts = () => {
        this.setState({
            ...this.state,
            treeData: this.state.accountList.map(
                (parentAccount: FullAWSAccountData, parentIndex: number) => {
                    return {
                        key: `${parentIndex}-root`,
                        title: parentAccount.pretty,
                        children: parentAccount.subAccounts?.map(
                            (subAccount: FullAWSAccountData, index: number) => {
                                return {
                                    key: `${index}-leaf-${parentIndex}`,
                                    title: subAccount.pretty,
                                    children: [],
                                    disabled: false,
                                    selectable: true,
                                    icon: (
                                        <EditTwoTone twoToneColor={'#FF8F43'} />
                                    ),
                                } as TreeDataType;
                            },
                        ),
                        disabled: false,
                        selectable: true,
                        icon: <EditTwoTone twoToneColor={'#FF8F43'} />,
                    };
                },
            ),
        });
    };

    confirmInput = async () => {
        if (
            this.state.accountSelected.data.pretty.length === 0 ||
            (!IsValidArn(this.state.accountSelected.data.roleArn) &&
                this.state.accountSelected.parentIndex === -1)
        )
            return DisplayPopupError(
                'You must enter a role name and a valid ARN.',
            );
        try {
            await callWithQueryParams(
                '/aws',
                'PATCH',
                {
                    'account-id': this.state.accountSelected.data.id,
                },
                this.state.accountSelected.data,
            );
            if (this.state.accountSelected.parentIndex != -1) {
                this.state.accountList[
                    this.state.accountSelected.index
                ].subAccounts[
                    this.state.accountSelected.index
                ] = this.state.accountSelected.data;
            } else
                this.state.accountList[
                    this.state.accountSelected.index
                ] = this.state.accountSelected.data;
            this.setState({
                ...this.state,
                accountList: this.state.accountList,
            });
        } catch (error) {
            if (
                error.response != null &&
                error.response.data != null &&
                error.response.data['error']
            )
                DisplayPopupError(
                    error.response.data['error'],
                    this.closeMainModal,
                );
            return;
        }
        Modal.success({
            content: 'ARN successfully edited.',
            centered: true,
            okCancel: false,
            onOk: this.closeMainModal,
        });
    };

    changeAddAccountModalState = async (value: boolean, reload?: boolean) => {
        this.setState({
            ...this.state,
            modalVisibility: {
                ...this.state.modalVisibility,
                accCreation: value,
            },
        });
        if (reload == undefined || reload) await this.getAccList();
    };

    displayMainTree = () => {
        return (
            <Tree
                treeData={this.state.treeData}
                switcherIcon={<DownOutlined />}
                showIcon={true}
                selectedKeys={[]}
                onSelect={(key: ReactText[]) => {
                    if (key.length === 0 || typeof key[0] !== 'string') return;
                    const strKey: string = key[0] as string;
                    if (strKey.search('root') !== -1)
                        return this.setSelectedAccount(parseInt(strKey, 10));
                    else
                        return this.setSelectedAccount(
                            parseInt(strKey, 10),
                            parseInt(
                                strKey.slice(strKey.lastIndexOf('-') + 1),
                                10,
                            ),
                        );
                }}
            />
        );
    };

    displayFooterButtons = () => {
        return (
            <>
                <Button
                    onClick={this.closeMainModal}
                    style={{
                        float: 'right',
                        overflow: 'auto',
                    }}
                    type="primary"
                    danger={true}
                >
                    Close
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        this.changeAddAccountModalState(true, false);
                    }}
                >
                    Add an account
                </Button>
            </>
        );
    };

    deleteAccount = async () => {
        try {
            await callWithQueryParams('/aws', 'DELETE', {
                'account-id': this.state.accountSelected.data.id,
            });
            await this.getAccList();
        } catch (error) {
            if (
                error.response != null &&
                error.response.data != null &&
                error.response.data['error']
            )
                DisplayPopupError(
                    error.response.data['error'],
                    this.closeMainModal,
                );
            return;
        }
        Modal.success({
            content: 'Account successfully deleted.',
            centered: true,
            okCancel: false,
            onOk: this.closeMainModal,
        });
    };

    displayMainModalFooterButton = () => {
        return (
            <>
                <br /> <br />
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            ...this.state,
                            loadingDeleteButton: true,
                        });
                        this.deleteAccount().then(() => {
                            this.setState({
                                ...this.state,
                                loadingDeleteButton: false,
                            });
                        });
                    }}
                    style={{
                        float: 'right',
                        overflow: 'auto',
                    }}
                    danger={true}
                    loading={this.state.loadingDeleteButton}
                >
                    <DeleteTwoTone twoToneColor="red" /> Delete
                </Button>
                <Button type="default" onClick={this.confirmInput}>
                    Confirm
                </Button>
            </>
        );
    };

    displayMainModal = () => {
        return (
            <Modal
                visible={this.state.modalVisibility.accSelected}
                onCancel={() => {
                    this.setState({
                        ...this.state,
                        modalVisibility: {
                            ...this.state.modalVisibility,
                            accSelected: false,
                        },
                    });
                }}
                footer={null}
            >
                <h3>Edit the current ARN:</h3>
                <Input
                    value={this.state.accountSelected.data.roleArn}
                    allowClear={true}
                    onPressEnter={this.confirmInput}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({
                            ...this.state,
                            accountSelected: {
                                ...this.state.accountSelected,
                                data: {
                                    ...this.state.accountSelected.data,
                                    roleArn: event.currentTarget.value,
                                },
                            },
                        });
                    }}
                />
                <br /> <br />
                <h3>Edit the role name associated to tag bot:</h3>
                <Input
                    value={this.state.accountSelected.data.pretty}
                    allowClear={true}
                    onPressEnter={this.confirmInput}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({
                            ...this.state,
                            accountSelected: {
                                ...this.state.accountSelected,
                                data: {
                                    ...this.state.accountSelected.data,
                                    pretty: event.currentTarget.value,
                                },
                            },
                        });
                    }}
                />
                {this.displayMainModalFooterButton()}
            </Modal>
        );
    };

    render = () => {
        return (
            <>
                <h1>List of your linked AWS accounts</h1>
                {this.displayMainTree()}
                <br />
                {this.displayFooterButtons()}
                <Modal
                    visible={this.state.modalVisibility.accCreation}
                    footer={null}
                >
                    {
                        <AddAwsAccount
                            setAttribute={
                                this
                                    .changeAddAccountModalState as React.Dispatch<
                                    React.SetStateAction<boolean>
                                >
                            }
                        />
                    }
                </Modal>
                {this.displayMainModal()}
            </>
        );
    };
}
