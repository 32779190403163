import React, { ReactNode, useState } from 'react';
import { Button, Modal, List } from 'antd';
import { LinkOutlined, TagsFilled } from '@ant-design/icons';
import { ResourceProprieties, ResourceTag } from './resource';

export const DisplayTagsList = (list: ResourceTag[]) => {
    if (list === undefined) return;
    list = list.sort((a, b) => a.key.localeCompare(b.key));
    const allData = new Map<string, ResourceTag>();
    list.forEach((k: ResourceTag) => {
        allData.set(k.key, k);
    });
    return (
        <>
            <List
                bordered={true}
                style={{ overflow: 'auto', maxHeight: 500 }}
                header={
                    <div style={{ textAlign: 'center' }}>
                        <strong> All resource tags </strong>
                    </div>
                }
                dataSource={list.map((tag: ResourceTag) => {
                    return tag.key;
                })}
                renderItem={(item: string): ReactNode => {
                    return (
                        <List.Item>
                            <List.Item.Meta
                                style={{ textAlign: 'center' }}
                                title={item}
                                description={allData.get(item)?.value}
                            />
                        </List.Item>
                    );
                }}
            ></List>
        </>
    );
};

export const DisplayHeader = (text: string, data: ResourceProprieties) => {
    return (
        <>
            {data.cloudFormationUrl !== undefined ? (
                <a
                    href={data.cloudFormationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={'/tag/cloud_formation.png'}
                        width={24}
                        height={24}
                    />
                </a>
            ) : null}
            <LinkOutlined />{' '}
            <a style={{ color: '#F18A40' }} href={data.url}>
                {text}
            </a>
        </>
    );
};

export const RenderID = (text: string, data: ResourceProprieties) => {
    const [infoVisible, setInfoVisible] = useState(false);

    return (
        <>
            <Modal
                closable={false}
                visible={infoVisible}
                footer={
                    <Button
                        danger
                        type="primary"
                        onClick={() => {
                            setInfoVisible(false);
                        }}
                    >
                        Cancel
                    </Button>
                }
            >
                {DisplayTagsList(data.tags)}
            </Modal>
            <Button
                block={false}
                icon={<TagsFilled />}
                onClick={() => setInfoVisible(true)}
                style={{ borderStyle: 'none' }}
            ></Button>
            {DisplayHeader(text, data)}
        </>
    );
};
