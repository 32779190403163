import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'shared/utils/storage';

const PrivateRoute = ({ component, ...rest }: any) => {
    const routeComponent = (props: any) =>
        getToken() ? (
            React.createElement(component, props)
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
