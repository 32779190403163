import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { call } from 'shared/utils';

export interface TreeDataType {
    key: string;
    title: ReactNode;
    children: TreeDataType[];
    disabled: false;
    selectable: true;
    icon: ReactNode;
}

export interface AWSAcc<T> {
    accountList: FullAWSAccountData[];
    setAttribute: React.Dispatch<React.SetStateAction<T>>;
    modalVisibility: {
        accSelected: boolean;
        accCreation: boolean;
    };
    accountSelected: {
        data: FullAWSAccountData;
        index: number;
        parentIndex: number;
    };
    treeData: Array<TreeDataType>;
    loadingDeleteButton: boolean;
}

export interface MinimalAWSAccountData {
    id: number;
    pretty: string;
    roleArn: string;
    payer: boolean;
    accountOwner: boolean;
    permissionLevel: number;
    awsIdentity: string;
}

interface AWSBillRepositoryBucket {
    id: number;
    awsAccountId: number;
    bucket: string;
    prefix: string;
    error: string;
    lastImportedManifest: string;
    nextUpdate: string;
    nextPending: boolean;
    status: {
        value: string;
        detail: string;
    };
}

export interface FullAWSAccountData extends MinimalAWSAccountData {
    billRepositories: AWSBillRepositoryBucket[];
    subAccounts: FullAWSAccountData[];
}

export interface GenericModalProprieties<T> {
    setAttribute: React.Dispatch<React.SetStateAction<T>>;
    attribute?: T;
}

export const UserHasAwsAcc = async (): Promise<boolean> => {
    const result = await call('/aws', 'GET');
    return result.data != null && result.data.length !== 0;
};

export const DisplayPopupError = (
    message: string,
    onOkCallback?: () => void,
) => {
    return Modal.error({
        title: 'Something went wrong...',
        content: message,
        centered: true,
        okCancel: false,
        onOk: onOkCallback,
    });
};

export const IsValidArn = (arn: string) => {
    const regex = /arn:aws:iam::[\d]{12}:role\/(?:[a-zA-Z0-9+=,.@_-](?:\/[a-zA-Z0-9+=,.@_-])?)+/;
    return arn.length > 0 && regex.test(arn);
};
