import React, { useContext } from 'react';
import { Modal } from 'antd';
import { HandleAWSAccounts } from '../../aws/editAWSAcc';
import { AddAwsAccount } from '../../aws/addAWSAcc';
import { ModalContext } from './ModalContext';

const AccountModal = () => {
    const { awsAccountModal, setAwsAccountModal, hasAwsAccLinked } = useContext(
        ModalContext,
    );

    return (
        <Modal
            visible={awsAccountModal}
            footer={null}
            onCancel={() => {
                setAwsAccountModal(false);
            }}
        >
            {hasAwsAccLinked.current ? (
                <HandleAWSAccounts setAttribute={setAwsAccountModal} />
            ) : (
                <AddAwsAccount setAttribute={setAwsAccountModal} />
            )}
        </Modal>
    );
};

export default AccountModal;
