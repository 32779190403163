/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Line } from 'react-chartjs-2';

// eslint-disable-next-line react/prop-types
const TagChart = ({ chartData, selectedCategory }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Chart = require('react-chartjs-2').Chart;

    const getLineTick = (value: number, index: number, arr: string | any[]) => {
        if (index === arr.length - 1) return ''; // don't show the last tick

        const remain =
            value / Math.pow(10, Math.floor(Chart.helpers.log10(value)));

        if (remain === 1 || remain === 2 || remain === 5) {
            return (
                Number(value).toLocaleString() +
                (value === 1 ? ' day ago' : ' days ago')
            );
        } else {
            return '';
        }
    };
    const filterDataByCategory = () => {
        if (selectedCategory === 'all') {
            return chartData.datasets;
        }

        // Filter datasets based on the selected category
        const filteredDatasets = chartData.datasets.filter(
            (dataset: any) =>
                dataset.label.trim().toLowerCase() ===
                selectedCategory.trim().toLowerCase(),
        );

        return filteredDatasets;
    };

    const filteredDatasets = filterDataByCategory();

    const filteredChartData = {
        ...chartData,
        datasets: filteredDatasets,
    };

    return (
        <Line
            data={filteredChartData}
            options={{
                maintainAspectRatio: false,
                responsive: true,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        title: () => {
                            return null; // Remove title
                        },
                        label: (tooltip: any, data: any) => {
                            return ` ${tooltip.value}% ${
                                data.datasets[tooltip.datasetIndex].label
                            } resources`;
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                min: 0,
                                max: 100,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'logarithmic',
                            ticks: {
                                reverse: true,
                                callback: getLineTick,
                            },
                        },
                    ],
                },
            }}
        />
    );
};

export default TagChart;
