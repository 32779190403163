export const layout = {
    labelCol: {
        sm: { span: 6, offset: 6 },
        md: { span: 6, offset: 6 },
        lg: { span: 6, offset: 9 },
    },
    wrapperCol: {
        sm: { span: 12, offset: 6 },
        md: { span: 12, offset: 6 },
        lg: { span: 6, offset: 9 },
    },
};
export const tailLayout = {
    wrapperCol: {
        sm: { offset: 6, span: 12 },
        md: { offset: 6, span: 12 },
        lg: { offset: 9, span: 6 },
    },
};
