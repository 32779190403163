import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

type FilterComponentProps = {
    selectedCategory: string;
    setSelectedCategory: (value: string) => void;
};

const FilterComponent = ({
    selectedCategory,
    setSelectedCategory,
}: FilterComponentProps) => {
    const handleChange = (value: string) => {
        setSelectedCategory(value);
    };

    return (
        <Select
            style={{ width: 120 }}
            value={selectedCategory}
            onChange={handleChange}
        >
            <Option value="all">All Categories</Option>
            <Option value="Fully Tagged">Fully Tagged</Option>
            <Option value="Partially Tagged">Partially Tagged</Option>
            <Option value="Totally untagged">Not Tagged</Option>
        </Select>
    );
};

export default FilterComponent;
