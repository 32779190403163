import React, { useContext } from 'react';
import { Modal } from 'antd';
import { CancelSubscription } from '../../stripe/CancelSubscription';
import { ModalContext } from './ModalContext';

const SubscriptionInfoModal = () => {
    const { setSubscriptionInfoModal, subscriptionInfoModal } = useContext(
        ModalContext,
    );

    return (
        <Modal
            footer={null}
            visible={subscriptionInfoModal}
            onCancel={() => {
                setSubscriptionInfoModal(false);
            }}
        >
            <CancelSubscription />
        </Modal>
    );
};

export default SubscriptionInfoModal;
