import React, { useState } from 'react';
import { Button, Input, Layout, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { call } from '../../shared/utils';
import {
    DisplayPopupError,
    GenericModalProprieties,
    IsValidArn,
} from './utils';

function DisplayTutorialText() {
    return (
        <>
            <h1>Tagbot AWS Access</h1>
            <Text>
                Tagbot uses cross-account Identity Access Management (IAM) roles
                to access tagging information in your AWS account.
            </Text>
            <br />
            <Text>
                Click the button below to launch an Amazon Cloudformation stack
                that will create the required IAM resources to securely access
                your account. Be sure to check the box at the bottom of the page
                to ensure the stack is applied properly.
            </Text>
            <br />
            <Text>
                Once the stack is completely applied, check the <b>Outputs</b>{' '}
                tab and make note of the <strong>Amazon Resource Name</strong> (
                <i>ARN</i>), as this information is needed for connection to
                your account.
            </Text>
            <Layout
                style={{
                    textAlign: 'center',
                    backgroundColor: 'unset',
                }}
            >
                <Text strong>The ARN should be:</Text>
                <Text code>arn:aws:iam::XXXXXXXXXX:role/TagbotViewAccess</Text>
                <br />
                <Text type="danger">
                    When you have noted the ARN, come back to this page and fill
                    the information.
                </Text>
            </Layout>
        </>
    );
}

export const AddAwsAccount = (
    proprieties: GenericModalProprieties<boolean>,
) => {
    const [tierVisibility, setTierVisibility] = useState(false);
    const [arn, setArn] = useState('');
    const [roleName, setRoleName] = useState('TagbotViewAccess');

    const redirectDashboard = () => {
        proprieties.setAttribute(false);
        setTierVisibility(false);
    };

    const handleOk = async () => {
        if (roleName.length === 0 || !IsValidArn(arn))
            return DisplayPopupError(
                'You must enter a role name and a valid ARN.',
            );
        try {
            const requestResult = await call('/aws/next', 'GET');
            await call('/aws', 'POST', {
                roleArn: arn,
                external: requestResult.data.external,
                pretty: roleName,
                payer: false,
                tagbotUser: true,
            });
        } catch (error) {
            if (
                error.response != null &&
                error.response.data != null &&
                error.response.data['error']
            )
                DisplayPopupError(
                    error.response.data['error'],
                    redirectDashboard,
                );
        }
        Modal.success({
            content:
                'Your AWS account has been linked to your tagbot account. It may take up to 12h to let tagbot fill your data.',
            centered: true,
            okCancel: false,
            onOk: redirectDashboard,
        });
    };

    const onValueChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.id === 'arn') setArn(event.currentTarget.value);
        else setRoleName(event.currentTarget.value);
    };

    const [loadingButton, setLoadingButton] = useState(false);
    return (
        <>
            <DisplayTutorialText />
            <br />
            <Layout
                style={{
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    backgroundColor: 'unset',
                }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        setTierVisibility(true);
                    }}
                    href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?stackName=Tagbot-IAM-role&templateURL=https://trackit-public-artifacts.s3-us-west-2.amazonaws.com/tagbot-iam-role.yml"
                    target="_blank"
                    block={false}
                    style={{ marginBottom: '10px' }}
                >
                    Launch stack
                </Button>
                <Button
                    onClick={redirectDashboard}
                    className="text-right"
                    danger={true}
                    type="primary"
                >
                    Cancel
                </Button>
            </Layout>
            <Modal
                visible={tierVisibility}
                confirmLoading={loadingButton}
                onOk={() => {
                    setLoadingButton(true);
                    handleOk().then(() => {
                        setLoadingButton(false);
                    });
                }}
                onCancel={() => {
                    setTierVisibility(false);
                }}
                centered={true}
            >
                <Text>Name the new role:</Text>
                <Input
                    id="roleName"
                    value={roleName}
                    onChange={onValueChange}
                />
                <br /> <br />
                <Text>Past the generated ARN below:</Text>
                <Input id="arn" value={arn} onChange={onValueChange} />
            </Modal>
        </>
    );
};
