import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';
import { ResourceProprieties } from './resource';
import { TagDataSuggestions } from 'assets/tag/tagSuggestions';
import { isMostUsedTags } from 'shared/utils';
import AwsLogo from 'shared/components/AwsLogo';
import { GlobalOutlined } from '@ant-design/icons';
import { RenderID } from './render_id';
import { RenderTag } from './tag/render';

export interface FilterContainer {
    type: string[];
    region: string[];
    [tags: string]: string[];
}

export interface TagColumn {
    suggestion: boolean;
    text: string;
    resourceUrl?: string;
    cloudFormationUrl?: string;
    dataSuggestion?: TagDataSuggestions[];
}

export const RemoveDuplicateFromArray = (
    array: string[],
    columnProperty: FilterProprieties[] | undefined,
): void => {
    array
        .filter(
            (item: string, i: number, array: string[]) =>
                array.indexOf(item) === i,
        )
        .forEach((item: string) => {
            columnProperty?.push({
                text: item,
                value: item,
            });
        });
};

export const RemoveDuplicateForMostUsedTags = (
    refFilters: FilterContainer,
    columns: ColumnsProprieties[],
) => {
    columns.forEach((column) => {
        if (isMostUsedTags(column.key)) {
            RemoveDuplicateFromArray(refFilters[column.key], column.filters);
        }
    });
};

export interface FilterProprieties {
    text: string;
    value: string;
}

export interface ColumnsProprieties {
    title: string;
    dataIndex: string;
    key: string;
    sorter?: (a: ResourceProprieties, b: ResourceProprieties) => number;
    sortDirections?: SortOrder[];
    render?:
        | ((value: string, data: ResourceProprieties) => React.ReactElement)
        | ((data: TagColumn) => React.ReactElement);
    filters?: FilterProprieties[];
    onFilter?(
        value: string | number | boolean,
        record: ResourceProprieties,
    ): boolean;
}

export const getTagSorter = (tagName: string) => {
    return (a: ResourceProprieties, b: ResourceProprieties) => {
        if (a[tagName] === undefined) return 1;
        if (b[tagName] === undefined) return -1;

        const aValue: TagColumn = a[tagName] as TagColumn;
        const bValue: TagColumn = b[tagName] as TagColumn;

        return aValue.text.localeCompare(bValue.text);
    };
};

export const columnResourceType = 1;
export const columnRegion = 2;

export const columns: ColumnsProprieties[] = [
    {
        title: 'ID',
        dataIndex: 'resourceId',
        key: 'resourceId',
        sorter: (a: ResourceProprieties, b: ResourceProprieties) =>
            a.resourceId.localeCompare(b.resourceId),
        sortDirections: ['ascend', 'descend'],
        // eslint-disable-next-line react/display-name
        render: RenderID,
    },
    {
        title: 'Type',
        dataIndex: 'resourceType',
        key: 'resourceType',
        sorter: (a: ResourceProprieties, b: ResourceProprieties) =>
            a.resourceType.localeCompare(b.resourceType),
        sortDirections: ['ascend', 'descend'],
        filters: [],
        onFilter: (
            value: string | number | boolean,
            record: ResourceProprieties,
        ): boolean => record.resourceType === value,
        // eslint-disable-next-line react/display-name
        render: (resourceType: string) => (
            <>
                <AwsLogo type={resourceType} />
                {' ' + resourceType}
            </>
        ),
    },
    {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        sorter: (a: ResourceProprieties, b: ResourceProprieties) =>
            a.region.localeCompare(b.region),
        sortDirections: ['ascend', 'descend'],
        filters: [],
        onFilter: (
            value: string | number | boolean,
            record: ResourceProprieties,
        ): boolean => record.region === value,
        // eslint-disable-next-line react/display-name
        render: (text: string) => (
            <>
                <GlobalOutlined />

                {' ' + text}
            </>
        ),
    },
    {
        title: 'Name', // Title for the column
        dataIndex: 'Name', // Assuming 'tags' is an array in ResourceProprieties type
        key: 'Name',
        sorter: getTagSorter('Name'), // Assuming "Name" is a tag; adjust if necessary
        sortDirections: ['ascend', 'descend'],
        filters: [],
        onFilter: (
            value: string | number | boolean,
            record: ResourceProprieties,
        ): boolean => {
            // Check if "Name" tag is present in the tags array
            return record.tags?.some((tag) => tag.key === 'Name') === value;
        },
        // eslint-disable-next-line react/display-name
        render: RenderTag,
    },
];
