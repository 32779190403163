import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import PaymentForm from './PaymentForm';
import { config, getToken } from 'shared/utils';
import { disconnect } from 'shared/utils/storage';
import { useHistory, useLocation } from 'react-router-dom';

export const CreateStripeCustomer = () => {
    const email = localStorage.getItem('email');
    const [tierVisibility, setTierVisibility] = useState(false);
    const [customerId, setCustomer] = useState('');
    const history = useHistory();
    const location = useLocation<{ from: string }>();

    const CreateCustomer = async () => {
        const token = getToken();
        if (token !== null)
            return fetch(config.API_URL + '/tagging/create-customer', {
                method: 'post',
                headers: {
                    Authorization: token,
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    localStorage.setItem('customerId', result.id);
                    setCustomer(result.id);
                });
    };

    return (
        <>
            <p>Your 2 weeks free trial period has expired.</p>
            <p>
                To continue using Tagbot, please subscribe for $19.99 per month
            </p>
            <Button
                type="primary"
                onClick={() => {
                    CreateCustomer();
                    setTierVisibility(true);
                }}
                className="text-right"
                danger={true}
                block={true}
            >
                Subscribe
            </Button>
            <Button
                style={{ marginTop: '10px' }}
                type="primary"
                onClick={() => {
                    disconnect(history, location);
                }}
                className="text-right"
                danger={true}
                block={true}
            >
                Disconnect
            </Button>
            <Modal
                title="Subscribe to Tagbot"
                visible={tierVisibility}
                footer={null}
            >
                <PaymentForm customerId={customerId} />
            </Modal>
        </>
    );
};
