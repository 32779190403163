import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { routes, Routes } from 'assets/routes';
import * as serviceWorker from './serviceWorker';
import './index.less';
import { PrivateRoute, PublicRoute } from 'shared/components';
import { loadSegment, config } from 'shared/utils';

const App = () => {
    useEffect(() => {
        loadSegment(config.SEGMENT_KEY);
    }, []);

    return (
        <Router>
            <Switch>
                {routes.map((route: Routes, key) =>
                    route.public ? (
                        <PublicRoute
                            key={key}
                            exact={route.exact}
                            path={route.path}
                            component={route.component}
                        />
                    ) : (
                        <PrivateRoute
                            key={key}
                            isAuthenticated={false}
                            exact={route.exact}
                            path={route.path}
                            component={route.component}
                        />
                    ),
                )}
            </Switch>
        </Router>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
