import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { DisplaySuggestedTagList } from './utils';
import { TagColumn } from '../table_columns';
import { SelectOutlined } from '@ant-design/icons';

export const RenderTag = (data: TagColumn) => {
    if (data == undefined) return <span />;

    if (data.suggestion) {
        const [suggestionVisibility, setSuggestionVisibility] = useState(false);
        return (
            <span
                style={{
                    color: '#b3b3b3',
                    fontStyle: 'italic',
                    fontSize: 'smaller',
                }}
            >
                <Modal
                    closable={false}
                    visible={suggestionVisibility}
                    footer={
                        <Button
                            danger
                            type="primary"
                            onClick={() => {
                                setSuggestionVisibility(false);
                            }}
                        >
                            Cancel
                        </Button>
                    }
                >
                    {DisplaySuggestedTagList(
                        data.dataSuggestion,
                        data.resourceUrl,
                        data.cloudFormationUrl,
                    )}
                </Modal>

                <Button
                    type="dashed"
                    size="middle"
                    onClick={() => {
                        setSuggestionVisibility(true);
                    }}
                >
                    {data.dataSuggestion != undefined &&
                    data.dataSuggestion.length > 1
                        ? 'Suggestions'
                        : 'Suggestion'}
                    <SelectOutlined />
                </Button>
            </span>
        );
    } else {
        return <span>{data.text}</span>;
    }
};
