import React, { useContext, useState } from 'react';
import { ModalContext } from '../ModalContext';
import { Modal, Radio } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { createWorkbookByRegion, createWorkbookByResourceType } from './utils';
import {
    getMostUsedTags,
    getUserAwsResources,
} from '../../../../shared/utils/api';

const ByType = 0;
const ByRegion = 1;
const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
} as React.CSSProperties;

export const ExportDataModal = () => {
    const { exportData, setExportDataModal } = useContext(ModalContext);
    const [opt, setOpt] = useState(ByType);
    const [isLoading, setLoadingState] = useState(false);
    return (
        <Modal
            visible={exportData}
            onCancel={() => {
                setExportDataModal(false);
            }}
            confirmLoading={isLoading}
            okText={<CheckOutlined />}
            cancelText={<CloseOutlined />}
            onOk={async () => {
                setLoadingState(true);
                const resources = await getUserAwsResources();
                const mostUsedTags = await getMostUsedTags();
                if (resources == null || mostUsedTags == null) {
                    setLoadingState(false);
                    setExportDataModal(false);
                    Modal.error({
                        title: 'Oops.. An error occurred',
                        content:
                            "Tagbot can't generate a report for the moment. Try again later.",
                        centered: true,
                        okCancel: false,
                    });
                    return;
                }
                if (opt == ByType)
                    await createWorkbookByResourceType(resources, mostUsedTags);
                else await createWorkbookByRegion(resources, mostUsedTags);
                setExportDataModal(false);
                setLoadingState(false);
            }}
        >
            How you want to export your data to a .xls file ?
            <br />
            <Radio.Group
                onChange={(e) => {
                    setOpt(e.target.value);
                }}
                defaultValue={ByType}
            >
                <Radio value={ByType} checked={true} style={radioStyle}>
                    By resource type
                </Radio>
                <Radio value={ByRegion} style={radioStyle}>
                    By region
                </Radio>
            </Radio.Group>
        </Modal>
    );
};
