import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Layout } from 'antd';
import TagbotHeader from './TagbotHeader';
import TagTable from './TagTable';
import ChartsContainer from './ChartsContainer';
import { call } from '../../shared/utils';
import { UserHasAwsAcc } from '../aws/utils';
import AccountModal from './Modals/AccountModal';
import SubscriptionInfoModal from './Modals/SubscriptionInfoModal';
import { disconnect } from '../../shared/utils/storage';
import { ModalContext, ModalContextValues } from './Modals/ModalContext';
import ReminderModal from './Modals/ReminderModal';
import { useHistory, useLocation } from 'react-router-dom';
import { ExportDataModal } from './Modals/ExportData/Modal';

const Dashboard = () => {
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const hasAwsAccLinked: React.MutableRefObject<boolean> = useRef(false);
    const [awsAccountModal, setAwsAccountModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [subscriptionInfoModal, setSubscriptionInfoModal] = useState(false);
    const [reminderVisibility, setReminderVisibility] = useState(false);
    const [exportData, setExportDataModal] = useState(false);

    const fetchSubscription = async () => {
        try {
            const res = await call(`/tagging/should-popup`, 'GET');
            if (res.data.popup === true) {
                setSubscriptionModal(true);
            }
        } catch (error) {
            if (error.response != null)
                if (error.response.status === 401)
                    disconnect(history, location);
        }
    };

    useEffect(() => {
        fetchSubscription();
    }, []);

    useCallback(async () => {
        window.onbeforeunload = function () {
            localStorage.removeItem('reminderPopup');
            return null;
        };
        return await UserHasAwsAcc().then((value: boolean) => {
            hasAwsAccLinked.current = value;
            if (localStorage.getItem('reminderPopup') == null) {
                setReminderVisibility(!value);
                localStorage.setItem('reminderPopup', 'exists');
            }
        });
    }, [])();

    const contextModalValues: ModalContextValues = {
        awsAccountModal: awsAccountModal,
        setAwsAccountModal: setAwsAccountModal,
        hasAwsAccLinked: hasAwsAccLinked,
        subscriptionModal: subscriptionModal,
        setSubscriptionModal: setSubscriptionModal,
        subscriptionInfoModal: subscriptionInfoModal,
        setSubscriptionInfoModal: setSubscriptionInfoModal,
        reminderVisibility: reminderVisibility,
        setReminderVisibility: setReminderVisibility,
        exportData: exportData,
        setExportDataModal: setExportDataModal,
    };

    return (
        <Layout hasSider={false}>
            <ModalContext.Provider value={contextModalValues}>
                <AccountModal />
                <SubscriptionInfoModal />
                <TagbotHeader />
                <ReminderModal />
                <ExportDataModal />
            </ModalContext.Provider>
            <ChartsContainer />
            <Layout style={{ backgroundColor: '#FFF', padding: '1rem' }}>
                <TagTable />
            </Layout>
        </Layout>
    );
};

export default Dashboard;
