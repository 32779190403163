import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'shared/utils/storage';

const PublicRoute = ({ component, ...rest }: any) => {
    const routeComponent = (props: any) =>
        getToken() &&
        (props.location.pathname === '/login' ||
            props.location.pathname === '/register') ? (
            <Redirect to={{ pathname: '/' }} />
        ) : (
            React.createElement(component, props)
        );
    return <Route {...rest} render={routeComponent} />;
};

export default PublicRoute;
