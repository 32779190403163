import { saveAs } from 'file-saver';
import { Alignment, Cell, Column, Workbook } from 'exceljs';
import { ResourceData, TagData } from '../../../../shared/utils/api';
import cover_setups from './cover_template.json';
import { call } from '../../../../shared/utils';

const createWorkBook = (): Workbook => {
    const workbook = new Workbook();
    workbook.creator = 'Tagbot';
    workbook.created = new Date();
    return workbook;
};

const saveWorkbook = async (book: Workbook) => {
    const buffer = await book.xlsx.writeBuffer();
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(
        blob,
        'Tagbot-report-' +
            localStorage.getItem('email') +
            '-' +
            Date.now().toString() +
            '.xlsx',
    );
};

interface RowContent {
    [key: string]: string;
}

const getResourceTagIndex = (resource: ResourceData, tag: string): number => {
    let idx = -1;
    resource.tags.forEach((value: TagData, index: number) => {
        if (value.key == tag) idx = index;
    });
    return idx;
};

const optionals: Array<string> = ['width'];

interface CellContent {
    cell: number;
    content: string;
    properties?: {
        style?: {
            alignement?: {
                horizontal: string;
                vertical: string;
            };
            fill?: {
                type: string;
                pattern: string;
                fgColor: {
                    argb: string;
                };
            };
            font?: {
                color: {
                    argb: string;
                };
            };
        };
    };
}

interface ColumnContent {
    column: string;
    texts: CellContent[];
    properties?: {
        width: string;
        style: {
            alignement: {
                horizontal: string;
                vertical: string;
            };
        };
    };
}

const applyProperties = (
    properties: [string, unknown][],
    obj: Column | Cell,
    optionals?: Array<string>,
) => {
    for (const [key, value] of properties) {
        if (key in obj || (optionals !== undefined && optionals.includes(key)))
            Object.defineProperty(obj, key, { value });
    }
};

const countResources = (
    resources: ResourceData[],
    mostUsedTags: Array<string>,
): number[] => {
    const view = new Array<number>();

    resources.forEach((resource: ResourceData) => {
        const total_valid = resource.tags.filter((element: TagData) =>
            mostUsedTags.includes(element.key),
        ).length;
        view.push(total_valid);
    });
    return [
        view.filter((num: number) => num > 0 && num < mostUsedTags.length)
            .length,
        view.filter((num: number) => num >= mostUsedTags.length).length,
        view.filter((num: number) => num <= 0).length,
    ];
};

const writeCoverContent = (
    cell: Cell,
    content: string,
    resources: ResourceData[],
    mostUsedTags: Array<string>,
) => {
    const total = resources.length;
    const totals: number[] = countResources(resources, mostUsedTags);
    content = content.replace('${date}', new Date().toLocaleDateString());
    content = content.replace('${total}', total.toString());
    content = content.replace('${partially}', totals[0].toString());
    content = content.replace('${tagged}', totals[1].toString());
    content = content.replace('${untagged}', totals[2].toString());
    cell.value = content;
};

const createCoverPage = (
    workbook: Workbook,
    resources: ResourceData[],
    mostUsedTags: Array<string>,
) => {
    const worksheet = workbook.addWorksheet('cover');
    //Setup Central
    cover_setups.forEach((obj) => {
        const column = { ...obj } as ColumnContent;
        if (obj.properties !== undefined)
            applyProperties(
                Object.entries(obj.properties),
                worksheet.getColumn(obj.column),
                optionals,
            );
        column.texts.forEach((text: CellContent) => {
            const cell = worksheet.getCell(obj.column + text.cell.toString());
            if (text.properties !== undefined)
                applyProperties(Object.entries(text.properties), cell);
            writeCoverContent(cell, text.content, resources, mostUsedTags);
        });
    });
};

export const createAndPopulateWorkbook = async (
    sheetList: string[],
    resources: ResourceData[],
    mostUsedTags: string[],
    selectRowData: (resource: ResourceData, sheetName: string) => boolean,
) => {
    const workbook = createWorkBook();
    const tagAbsent = 'N/A';
    const columnFormat = [
        {
            header: 'ID',
            key: 'id',
            width: 10,
        },
        {
            header: 'Account owner',
            key: 'account',
            width: 10,
        },
        {
            header: 'Region',
            key: 'region',
            width: 10,
        },
        {
            header: 'Type',
            key: 'type',
            width: 10,
        },
        {
            header: 'Name',
            key: 'Name',
            width: 10,
        },
        {
            header: 'Most used tags',
            key: '__tags',
            width: 10,
        },
    ] as Array<Partial<Column>>;
    const mostUsedTagsRes = await call('/tagging/mostusedtags', 'GET');
    createCoverPage(workbook, resources, mostUsedTagsRes.data.mostUsedTags);
    mostUsedTags.forEach((tag: string) => {
        columnFormat.push({
            header: tag,
            key: tag,
            width: 10,
            style: {
                alignment: {
                    horizontal: 'center',
                    vertical: 'middle',
                },
            },
        });
    });
    sheetList.forEach((sheetName: string) => {
        const worksheet = workbook.addWorksheet(sheetName);
        worksheet.columns = columnFormat;
        worksheet.eachColumnKey((col: Column) => {
            col.eachCell((cell: Cell) => {
                cell.alignment = {
                    horizontal: 'center',
                    vertical: 'middle',
                } as Alignment;
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'FF000000',
                    },
                };
                cell.font = {
                    color: {
                        argb: 'FFFFFFFF',
                    },
                };
            });
        });
        resources.forEach((resource: ResourceData) => {
            if (!selectRowData(resource, sheetName)) return;
            const nameTag = resource.tags.find((tag) => tag.key === 'Name');
            const name = nameTag ? nameTag.value : '';
            const rowData = {
                id: resource.resourceId,
                account: resource.account,
                region: resource.region,
                type: resource.resourceType,
                name: name,
            } as RowContent;
            if (rowData == null) return;
            mostUsedTags.forEach((value: string) => {
                const tagIndex = getResourceTagIndex(resource, value);
                rowData[value] =
                    tagIndex > 0 ? resource.tags[tagIndex].value : tagAbsent;
            });
            const newRow = worksheet.addRow(rowData);
            newRow.eachCell((cell: Cell, colNumber: number) => {
                if (colNumber < 5) return;
                let color = 'FFFF0000';
                if (cell.text !== tagAbsent) color = 'FF009933';
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: color,
                    },
                };
            });
        });
        worksheet.eachColumnKey((column: Column) => {
            let maxCellSize = 0;
            column.eachCell((cell: Cell) => {
                maxCellSize = Math.max(cell.text.length, maxCellSize);
            });
            column.width = maxCellSize + 2;
        });
        worksheet.mergeCells(1, 5, worksheet.rowCount, 5);
    });
    await saveWorkbook(workbook);
};

export const createWorkbookByResourceType = async (
    resources: ResourceData[],
    mostUsedTags: string[],
) => {
    const listingByType: string[] = [];
    resources.forEach((resource: ResourceData) => {
        if (listingByType.indexOf(resource.resourceType) === -1)
            listingByType.push(resource.resourceType);
    });
    await createAndPopulateWorkbook(
        listingByType,
        resources,
        mostUsedTags,
        (resource: ResourceData, sheetName: string) => {
            return resource.resourceType === sheetName;
        },
    );
};

export const createWorkbookByRegion = async (
    resources: ResourceData[],
    mostUsedTags: string[],
) => {
    const listingByRegion: string[] = [];
    resources.forEach((resource: ResourceData) => {
        if (listingByRegion.indexOf(resource.region) === -1)
            listingByRegion.push(resource.region);
    });
    await createAndPopulateWorkbook(
        listingByRegion,
        resources,
        mostUsedTags,
        (resource: ResourceData, sheetName: string) => {
            return resource.region === sheetName;
        },
    );
};
