import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { call } from 'shared/utils';
import { config, getToken } from 'shared/utils';
import ChangeCardForm from './ChangeCardForm';

interface CustomerInfo {
    customerId: string;
    subscriptionId: string;
    cardInfo: string;
    isSubscribed: boolean;
}

export const CancelSubscription = () => {
    const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [tierVisibility, setTierVisibility] = useState(false);

    const fetchCustomerInfo = async () => {
        const res = await call(`/tagging/stripe-customer-information`, 'GET');
        if (res.data.paymentMethod == '') {
            setCustomerInfo({
                customerId: res.data.customerId,
                subscriptionId: res.data.subscriptionId,
                cardInfo: '',
                isSubscribed: res.data.isSubscribed,
            });
        } else {
            setCustomerInfo({
                customerId: res.data.customerId,
                subscriptionId: res.data.subscriptionId,
                cardInfo:
                    res.data.paymentMethod.card.brand +
                    ' •••• ' +
                    res.data.paymentMethod.card.last4,
                isSubscribed: res.data.isSubscribed,
            });
        }
    };

    const handleCancel = () => {
        if (customerInfo && customerInfo.subscriptionId) {
            const token = getToken();
            if (token !== null)
                return fetch(config.API_URL + '/tagging/cancel-subscription', {
                    method: 'post',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        subscriptionId: customerInfo.subscriptionId,
                    }),
                }).then((response) => {
                    return response.json();
                });
        }
    };

    useEffect(() => {
        fetchCustomerInfo();
    }, [customerInfo]);

    return (
        <>
            <h1>Account settings</h1>
            <Text>Subscription: </Text>
            {customerInfo?.isSubscribed ? (
                <Text>Active</Text>
            ) : (
                <Text>None</Text>
            )}
            <br /> <br />
            <Text>Credit Card: </Text>
            {customerInfo?.cardInfo ? (
                <>
                    <Text>{customerInfo.cardInfo}</Text>
                    <br /> <br />
                    <Button
                        type="link"
                        onClick={() => {
                            setTierVisibility(true);
                        }}
                    >
                        Change credit card
                    </Button>
                    <Modal
                        title="Change Credit Card"
                        visible={tierVisibility}
                        footer={
                            <Button
                                key="No"
                                onClick={() => {
                                    setTierVisibility(false);
                                }}
                            >
                                Cancel
                            </Button>
                        }
                    >
                        <ChangeCardForm customerId={customerInfo.customerId} />
                    </Modal>
                    <br></br>
                    <Button
                        type="link"
                        onClick={() => {
                            setCancelConfirmation(true);
                        }}
                    >
                        Cancel Subscription
                    </Button>
                    <Modal
                        title="Confirmation"
                        visible={cancelConfirmation}
                        footer={[
                            <Button
                                key="No"
                                onClick={() => {
                                    setCancelConfirmation(false);
                                }}
                            >
                                No
                            </Button>,
                            <Button
                                key="Yes"
                                onClick={() => {
                                    handleCancel();
                                }}
                            >
                                Yes
                            </Button>,
                        ]}
                    >
                        <p>
                            Are you sure you want to cancel your subscription?
                        </p>
                    </Modal>
                </>
            ) : (
                <Text>None</Text>
            )}
        </>
    );
};
