import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { barChartData } from '../../assets/barChartData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TagDoughnut = ({ latestComplianceData }: any) => {
    return (
        <Doughnut
            height={165}
            data={barChartData(latestComplianceData)}
            options={{
                cutoutPercentage: 80,
                legend: { display: false },
                tooltips: {
                    callbacks: {
                        label: function (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            tooltipItems: any,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data: any,
                        ) {
                            return data.labels[tooltipItems.index];
                        },
                    },
                },
                showAllTooltips: false,
                elements: {
                    center: {
                        text: "Today's tagging",
                        color: '#575757',
                        fontStyle: 'Helvetica Neue',
                        sidePadding: 20,
                    },
                },
            }}
        />
    );
};

export default TagDoughnut;
