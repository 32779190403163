/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    CardElement,
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { config, getToken, call } from 'shared/utils';
import { Modal, Form, Input } from 'antd';

const stripePromise = loadStripe(config.STRIPE_KEY);

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

interface CreateSubscription {
    customerId: string | null;
    paymentMethodId: string;
    priceId: string;
}

interface RetryInvoiceWithNewPaymentMethod {
    customerId: string | null;
    paymentMethodId: string;
    invoiceId: string | null;
    priceId: string;
}

interface HandlePaymentThatRequiresCustomerAction {
    subscription: any;
    invoice: any;
    priceId: string;
    paymentMethodId: string;
    isRetry: boolean;
}

interface HandleRequiresPaymentMethod {
    subscription: any;
    paymentMethodId: string;
    priceId: string;
}

interface Props {
    customerId: string;
}

const CheckoutForm = ({ customerId }: Props) => {
    const stripe = useStripe();
    const elements = useElements();
    const priceId = 'tagbot';
    const [errorToDisplay, setErrorToDisplay] = useState<string | undefined>(
        '',
    );
    const [SubscriptionSuccess, setSubscriptionSuccess] = useState(false);
    const [SubscriptionFail, setSubscriptionFail] = useState(false);
    const [Subscribing, setSubscribing] = useState(true);
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');

    const retrieveSubscription = async (subscriptionId: string) => {
        const res = await call('/tagging/retrieve-subscription', 'POST', {
            subscriptionId: subscriptionId,
        });
        if (res.data.status === 'active') {
            setSubscriptionSuccess(true);
            localStorage.removeItem('latestInvoiceId');
            localStorage.removeItem('latestInvoicePaymentIntentStatus');
        } else {
            setSubscriptionFail(true);
        }
    };

    function onSubscriptionComplete(result: any) {
        retrieveSubscription(result.subscription.id);
        setSubscribing(false);
    }

    function handlePaymentThatRequiresCustomerAction({
        subscription,
        invoice,
        priceId,
        paymentMethodId,
        isRetry,
    }: HandlePaymentThatRequiresCustomerAction) {
        if (!stripe) {
            return;
        }
        if (subscription && subscription.status === 'active') {
            return { subscription, priceId, paymentMethodId };
        }

        const paymentIntent = invoice
            ? invoice.payment_intent
            : subscription.latest_invoice.payment_intent;
        if (
            paymentIntent.status === 'requires_action' ||
            (isRetry === true &&
                paymentIntent.status === 'requires_payment_method')
        ) {
            return stripe
                .confirmCardPayment(paymentIntent.client_secret, {
                    payment_method: paymentMethodId,
                })
                .then((result) => {
                    if (result.error) {
                        setErrorToDisplay(result.error.message);
                        throw result;
                    } else {
                        if (result.paymentIntent) {
                            if (result.paymentIntent.status === 'succeeded') {
                                return {
                                    priceId: priceId,
                                    subscription: subscription,
                                    invoice: invoice,
                                    paymentMethodId: paymentMethodId,
                                };
                            }
                        }
                    }
                })
                .catch(() => {
                    setSubscriptionFail(true);
                    setSubscribing(false);
                });
        } else {
            return { subscription, priceId, paymentMethodId };
        }
    }

    function handleRequiresPaymentMethod({
        subscription,
        paymentMethodId,
        priceId,
    }: HandleRequiresPaymentMethod) {
        if (subscription.status === 'active') {
            return { subscription, priceId, paymentMethodId };
        } else if (
            subscription.latest_invoice.payment_intent.status ===
            'requires_payment_method'
        ) {
            localStorage.setItem(
                'latestInvoiceId',
                subscription.latest_invoice.id,
            );
            localStorage.setItem(
                'latestInvoicePaymentIntentStatus',
                subscription.latest_invoice.payment_intent.status,
            );
            setErrorToDisplay('Your card was declined.');
            throw { error: { message: 'Your card was declined.' } };
        } else {
            return { subscription, priceId, paymentMethodId };
        }
    }

    function retryInvoiceWithNewPaymentMethod({
        customerId,
        paymentMethodId,
        invoiceId,
        priceId,
    }: RetryInvoiceWithNewPaymentMethod) {
        const token = getToken();
        if (token !== null)
            return (
                fetch(config.API_URL + '/tagging/retry-invoice', {
                    method: 'post',
                    headers: {
                        Authorization: token,
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        customerId: customerId,
                        paymentMethodId: paymentMethodId,
                        invoiceId: invoiceId,
                    }),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((result) => {
                        if (result.error) {
                            setSubscriptionFail(true);
                            setSubscribing(false);
                            setErrorToDisplay(result.error.message);
                            throw result;
                        }
                        return result;
                    })
                    .then((result) => {
                        return {
                            invoice: result,
                            paymentMethodId: paymentMethodId,
                            priceId: priceId,
                            isRetry: true,
                        };
                    })
                    // @ts-ignore
                    .then(handlePaymentThatRequiresCustomerAction)
                    .then(onSubscriptionComplete)
                    .catch(() => {
                        setSubscriptionFail(true);
                        setSubscribing(false);
                    })
            );
    }

    function createSubscription({
        customerId,
        paymentMethodId,
        priceId,
    }: CreateSubscription) {
        const token = getToken();
        if (token !== null)
            return (
                fetch(config.API_URL + '/tagging/create-subscription', {
                    method: 'post',
                    headers: {
                        Authorization: token,
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        customerId: customerId,
                        paymentMethodId: paymentMethodId,
                        priceId: priceId,
                    }),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((result) => {
                        if (result.error) {
                            setSubscriptionFail(true);
                            setSubscribing(false);
                            setErrorToDisplay(result.error.message);
                            throw result;
                        }
                        return result;
                    })
                    .then((result) => {
                        return {
                            paymentMethodId: paymentMethodId,
                            priceId: priceId,
                            subscription: result,
                        };
                    })
                    // @ts-ignore
                    .then(handlePaymentThatRequiresCustomerAction)
                    .then(handleRequiresPaymentMethod)
                    .then(onSubscriptionComplete)
                    .catch(() => {
                        setSubscriptionFail(true);
                        setSubscribing(false);
                    })
            );
    }

    const handleSubmit = async () => {
        localStorage.removeItem('latestInvoiceId');
        localStorage.removeItem('latestInvoicePaymentIntentStatus');

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const latestInvoicePaymentIntentStatus = localStorage.getItem(
            'latestInvoicePaymentIntentStatus',
        );
        if (cardElement) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: name,
                    address: {
                        city: city,
                        line1: line1,
                        line2: line2,
                        postal_code: postalCode,
                        state: state,
                    },
                },
            });

            if (error) {
                setSubscriptionFail(true);
                setSubscribing(false);
                setErrorToDisplay(error.message);
            } else {
                if (paymentMethod) {
                    const paymentMethodId = paymentMethod.id;
                    if (
                        latestInvoicePaymentIntentStatus ===
                        'requires_payment_method'
                    ) {
                        const invoiceId = localStorage.getItem(
                            'latestInvoiceId',
                        );
                        retryInvoiceWithNewPaymentMethod({
                            customerId,
                            paymentMethodId,
                            invoiceId,
                            priceId,
                        });
                    } else {
                        createSubscription({
                            customerId,
                            paymentMethodId,
                            priceId,
                        });
                    }
                }
            }
        }
    };

    const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
    };

    const onCityChange = (event: React.FormEvent<HTMLInputElement>) => {
        setCity(event.currentTarget.value);
    };

    const onCountryChange = (event: React.FormEvent<HTMLInputElement>) => {
        setCountry(event.currentTarget.value);
    };

    const onLine1Change = (event: React.FormEvent<HTMLInputElement>) => {
        setLine1(event.currentTarget.value);
    };

    const onLine2Change = (event: React.FormEvent<HTMLInputElement>) => {
        setLine2(event.currentTarget.value);
    };

    const onPostalCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPostalCode(event.currentTarget.value);
    };

    const onStateChange = (event: React.FormEvent<HTMLInputElement>) => {
        setState(event.currentTarget.value);
    };

    if (Subscribing) {
        return (
            <>
                <Form layout="vertical" onFinish={() => handleSubmit()}>
                    <Form.Item
                        label="Full name"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name and lastname!',
                            },
                        ]}
                    >
                        <Input
                            id="name"
                            value={name}
                            onChange={onNameChange}
                            placeholder="First and last name"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Billing address"
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your country',
                            },
                        ]}
                    >
                        <Input
                            id="Country"
                            value={country}
                            onChange={onCountryChange}
                            placeholder="Country"
                        />
                    </Form.Item>
                    <Form.Item
                        name="address_line_1"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your primary address',
                            },
                        ]}
                    >
                        <Input
                            id="Line 1"
                            value={line1}
                            onChange={onLine1Change}
                            placeholder="Address Line 1"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            id="Line 2"
                            value={line2}
                            onChange={onLine2Change}
                            placeholder="Address Line 2"
                        />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your city',
                            },
                        ]}
                    >
                        <Input
                            id="City"
                            value={city}
                            onChange={onCityChange}
                            placeholder="City"
                        />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your state',
                            },
                        ]}
                    >
                        <Input
                            id="State"
                            value={state}
                            onChange={onStateChange}
                            placeholder="State"
                        />
                    </Form.Item>
                    <Form.Item
                        name="zip"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your postal code',
                            },
                        ]}
                    >
                        <Input
                            id="ZIP"
                            value={postalCode}
                            onChange={onPostalCodeChange}
                            placeholder="ZIP / Postal Code"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Credit Card"
                        name="credit_card"
                        required={true}
                    >
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </Form.Item>
                    <button type="submit" disabled={!stripe}>
                        Pay
                    </button>
                </Form>
            </>
        );
    } else {
        return (
            <>
                <Modal
                    title="Thank you for Subscribing"
                    visible={SubscriptionSuccess}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onOk={() => {
                        window.location.reload();
                    }}
                >
                    <p>You have successfully subscribed to Tagbot.</p>
                    <p>Press OK to continue.</p>
                </Modal>
                <Modal
                    title="An error has occurred"
                    visible={SubscriptionFail}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onOk={() => {
                        window.location.reload();
                    }}
                >
                    <p>{errorToDisplay}</p>
                    <p>The subscription was not activated.</p>
                </Modal>
            </>
        );
    }
};

const PaymentForm = ({ customerId }: Props) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm customerId={customerId} />
    </Elements>
);

export default PaymentForm;
