/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
    DeploymentUnitOutlined,
    DatabaseOutlined,
    PartitionOutlined,
    RadarChartOutlined,
    HddOutlined,
    ApartmentOutlined,
    ShareAltOutlined,
    NodeIndexOutlined,
    RestOutlined,
} from '@ant-design/icons';

const awsLogoMap: any = {
    lambda: <DeploymentUnitOutlined />,
    ec2: <HddOutlined />,
    'ec2-ri': <HddOutlined />,
    ebs: <DatabaseOutlined />,
    rds: <DatabaseOutlined />,
    'rds-ri': <DatabaseOutlined />,
    elasticcache: <RadarChartOutlined />,
    es: <RadarChartOutlined />,
    stepfunction: <PartitionOutlined />,
    cloudformation: <ApartmentOutlined />,
    route53: <ShareAltOutlined />,
    sqs: <NodeIndexOutlined />,
    s3: <RestOutlined />,
    default: <DatabaseOutlined />,
};

const AwsLogo = ({ type }: any) => {
    return awsLogoMap[awsLogoMap[type] ? type : 'default'];
};

export default AwsLogo;
