import React, { useContext } from 'react';
import { Modal } from 'antd';
import { ModalContext } from './ModalContext';

const ReminderModal = () => {
    const {
        reminderVisibility,
        setReminderVisibility,
        setAwsAccountModal,
    } = useContext(ModalContext);

    return (
        <Modal
            title="Hey! Wait a minute..."
            visible={reminderVisibility}
            onOk={() => {
                setReminderVisibility(false);
                setAwsAccountModal(true);
            }}
            onCancel={() => {
                setReminderVisibility(false);
            }}
            okText="Yes"
            cancelText="No"
            closable={false}
        >
            <span style={{ color: '#ff0000' }}>
                You don&apos;t seem to have an AWS account linked to your tagbot
                account.
            </span>
            <br />
            <p>
                <strong>Do you want to do it now?</strong>
            </p>
            <i>
                You can do it later by clicking on the
                <strong> &apos;AWS Settings &apos; </strong> button which can be
                found in the drop-down menu in the top right corner.
            </i>
        </Modal>
    );
};

export default ReminderModal;
