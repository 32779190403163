import styled from 'styled-components';
import { Menu } from 'antd';

export const CustomMenu = styled(Menu)`
    .ant-menu-item:hover {
        color: transparent !important;
        border-bottom: 2px solid transparent !important;
    }
    .ant-menu-item-selected {
        color: transparent !important;
        border-bottom: 2px solid transparent !important;
    }
`;
