import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    CardElement,
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Form, Input, Modal } from 'antd';
import { config, getToken } from 'shared/utils';

const stripePromise = loadStripe(config.STRIPE_KEY);

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

interface Props {
    customerId: string;
}

const CheckoutForm = (_props: Props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [ChangeCardSuccess, setChangeCardSuccess] = useState(false);
    const [ChangeCardFail, setChangeCardFail] = useState(false);
    const [ChangingCard, setChangingCard] = useState(true);

    function changeCard(paymentMethodId: string) {
        const token = getToken();
        if (token !== null) {
            return fetch(config.API_URL + '/tagging/change-payment-method', {
                method: 'post',
                headers: {
                    Authorization: token,
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethodId,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    if (result.error) {
                        setChangeCardFail(true);
                        throw result;
                    }
                    setChangeCardSuccess(true);
                    return result;
                })
                .catch(() => {
                    setChangeCardFail(true);
                });
        }
    }

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: name,
                    address: {
                        city: city,
                        line1: line1,
                        line2: line2,
                        postal_code: postalCode,
                        state: state,
                    },
                },
            });

            if (error) {
                setChangingCard(false);
                setChangeCardFail(true);
            } else {
                if (paymentMethod) {
                    const paymentMethodId = paymentMethod.id;
                    changeCard(paymentMethodId);
                    setChangingCard(false);
                }
            }
        }
    };

    const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
    };

    const onCityChange = (event: React.FormEvent<HTMLInputElement>) => {
        setCity(event.currentTarget.value);
    };

    const onCountryChange = (event: React.FormEvent<HTMLInputElement>) => {
        setCountry(event.currentTarget.value);
    };

    const onLine1Change = (event: React.FormEvent<HTMLInputElement>) => {
        setLine1(event.currentTarget.value);
    };

    const onLine2Change = (event: React.FormEvent<HTMLInputElement>) => {
        setLine2(event.currentTarget.value);
    };

    const onPostalCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPostalCode(event.currentTarget.value);
    };

    const onStateChange = (event: React.FormEvent<HTMLInputElement>) => {
        setState(event.currentTarget.value);
    };

    if (ChangingCard) {
        return (
            <>
                <Form layout="vertical" onFinish={() => handleSubmit()}>
                    <Form.Item
                        label="Full name"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name and lastname!',
                            },
                        ]}
                    >
                        <Input
                            id="name"
                            value={name}
                            onChange={onNameChange}
                            placeholder="First and last name"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Billing address"
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your country',
                            },
                        ]}
                    >
                        <Input
                            id="Country"
                            value={country}
                            onChange={onCountryChange}
                            placeholder="Country"
                        />
                    </Form.Item>
                    <Form.Item
                        name="address_line_1"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your primary address',
                            },
                        ]}
                    >
                        <Input
                            id="Line 1"
                            value={line1}
                            onChange={onLine1Change}
                            placeholder="Address Line 1"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            id="Line 2"
                            value={line2}
                            onChange={onLine2Change}
                            placeholder="Address Line 2"
                        />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your city',
                            },
                        ]}
                    >
                        <Input
                            id="City"
                            value={city}
                            onChange={onCityChange}
                            placeholder="City"
                        />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your state',
                            },
                        ]}
                    >
                        <Input
                            id="State"
                            value={state}
                            onChange={onStateChange}
                            placeholder="State"
                        />
                    </Form.Item>
                    <Form.Item
                        name="zip"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your postal code',
                            },
                        ]}
                    >
                        <Input
                            id="ZIP"
                            value={postalCode}
                            onChange={onPostalCodeChange}
                            placeholder="ZIP / Postal Code"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Credit Card"
                        name="credit_card"
                        required={true}
                    >
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </Form.Item>
                    <button type="submit" disabled={!stripe}>
                        Change
                    </button>
                </Form>
            </>
        );
    } else {
        return (
            <>
                <Modal
                    title="Credit card change"
                    visible={ChangeCardSuccess}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onOk={() => {
                        window.location.reload();
                    }}
                >
                    <p>
                        You have successfully changed your credit card
                        information.
                    </p>
                    <p>Press OK to continue.</p>
                </Modal>
                <Modal
                    title="An error has occurred"
                    visible={ChangeCardFail}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onOk={() => {
                        window.location.reload();
                    }}
                >
                    <p>The credit card was not changed.</p>
                </Modal>
            </>
        );
    }
};

const ChangeCardForm = ({ customerId }: Props) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm customerId={customerId} />
    </Elements>
);

export default ChangeCardForm;
