import { TagDataSuggestions } from './tagSuggestions';
import { Button, List, Popover } from 'antd';
import React, { ReactNode } from 'react';

const determineColorFromPercentage = (percentage: number | undefined) => {
    if (percentage == undefined) return '#000000';
    const colors: Array<string> = [
        '#FF0000',
        '#FF7F00',
        '#FFCC00',
        '#66cc00',
        '#408000',
    ];
    let index = Math.floor(percentage / 20) as number;
    if (index >= colors.length) index = colors.length - 1;
    return colors[index];
};

const createLocalButton = (
    url: string | undefined,
    key: string,
    img: string,
    alt: string,
) => {
    return (
        <Popover content={`This button redirect you to ${alt}`}>
            <Button
                key={key}
                icon={<img src={img} alt={alt} width={24} height={24} />}
                href={url}
                target="_blank"
                block={false}
            />
        </Popover>
    );
};

export const DisplaySuggestedTagList = (
    list: TagDataSuggestions[] | undefined,
    url: string | undefined,
    cloudFormationUrl: string | undefined,
) => {
    if (list == undefined) return;
    const allData = new Map<string, TagDataSuggestions>();
    list.forEach((k: TagDataSuggestions) => {
        allData.set(k.value, k);
    });
    return (
        <>
            <List
                header={
                    <div style={{ textAlign: 'center' }}>
                        <strong>
                            Tags suggestion depending on a confidence rate
                        </strong>
                    </div>
                }
                bordered={true}
                dataSource={list.map((k: TagDataSuggestions) => {
                    return k.value;
                })}
                renderItem={(item: string): ReactNode => {
                    return (
                        <List.Item
                            actions={
                                cloudFormationUrl !== undefined
                                    ? [
                                          createLocalButton(
                                              url,
                                              'AWS-' + url + allData.get(item),
                                              '/tag/aws.png',
                                              'AWS',
                                          ),
                                          createLocalButton(
                                              cloudFormationUrl,
                                              'CF-' +
                                                  cloudFormationUrl +
                                                  allData.get(item),
                                              '/tag/cloud_formation.png',
                                              'cloud formation',
                                          ),
                                      ]
                                    : [
                                          createLocalButton(
                                              url,
                                              'AWS-' + url + allData.get(item),
                                              '/tag/aws.png',
                                              'AWS',
                                          ),
                                      ]
                            }
                        >
                            <List.Item.Meta title={item} />
                            <div
                                style={{
                                    color: determineColorFromPercentage(
                                        allData.get(item)?.confidence,
                                    ),
                                }}
                            >
                                <strong>
                                    {allData.get(item)?.confidence}%
                                </strong>
                            </div>
                        </List.Item>
                    );
                }}
            />
        </>
    );
};
