import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Layout, Input, Button, Alert } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { call, saveToken, trackEvents } from 'shared/utils';
import { layout, tailLayout } from './layouts';
import { History, Location } from 'history';

export const LogUser = async (
    email: string,
    password: string,
    history: History,
    location: Location<{ from: string }>,
) => {
    try {
        const res = await call('/user/login', 'POST', {
            email,
            password,
            origin: 'tagbot',
        });

        if (typeof res.data.token !== 'string') throw new Error();
        saveToken(res.data.token);
        localStorage.setItem('email', res.data.user.email);
        // Redirect user
        trackEvents({
            event: 'Logged In',
            properties: {
                value: res.data.user.email,
                category: 'Account',
                label: 'User connected',
            },
        });
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
    } catch (error) {
        if (error.response != null && error.response.status === 403)
            throw new Error('Wrong email or password.');
        else throw new Error('Could not login. Please try again.');
    }
};

const Login = () => {
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const [error, setError] = useState('');

    const onFinish = async (values: Store) => {
        const { email, password } = values;
        try {
            await LogUser(email, password, history, location);
        } catch (e) {
            setError(e.toString());
        }
    };

    return (
        <Layout
            style={{
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
            }}
        >
            <img className={'auth-logo'} src="logo.png" alt="logo" />
            <Form
                layout={'vertical'}
                style={{ width: '100%' }}
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email address!',
                            type: 'email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                    >
                        Sign In
                    </Button>
                </Form.Item>

                <Form.Item
                    {...tailLayout}
                    name="remember"
                    valuePropName="checked"
                    style={{ textAlign: 'center' }}
                >
                    <Button
                        type="primary"
                        style={{ width: '100%', marginBottom: '10px' }}
                        href="https://aws.amazon.com/marketplace/pp/B08F7YHRQ5"
                    >
                        Sign Up from AWS Marketplace
                    </Button>
                    <a
                        onClick={() => history.replace('/register')}
                        className="login-form-forgot"
                        style={{ textDecoration: 'underline' }}
                    >
                        Use classic sign up
                    </a>
                </Form.Item>

                {error && (
                    <Form.Item {...tailLayout}>
                        <Alert message={error} type="error" showIcon />
                    </Form.Item>
                )}
            </Form>
        </Layout>
    );
};

export default Login;
